import { useState, useEffect } from 'react';
import {
  LinearProgress,
  FormControl,
  InputAdornment,
  IconButton,
  useTheme,
  styled,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(props => {
  return <TextField {...props} />;
})(({ theme }) => {
  return {
    '& .MuiInputLabel-root': {
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiFilledInput-root': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiOutlinedInput-root': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)',
    },
    '& .MuiOutlined-input-input': {
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(255, 255, 255, 0.17)'
          : theme.palette.text.primary,
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.17)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '& > fieldset': {
        borderColor:
          theme.palette.type === 'light'
            ? 'rgba(0, 0, 0, 0.13)'
            : 'rgba(255, 255, 255, 0.13)',
      },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
    },
    '& .MuiAutocomplete-popupIndicator.Mui-disabled': {
      color:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
    },
    '.MuiFormLabel-root.Mui-disabled': {
      color:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)',
    },
    input: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': `0 0 0 100px ${theme.palette.type === 'light' ? 'white' : theme.palette.paper.main
          } inset`,
        '-webkit-text-fill-color':
          theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
      },
    },
  };
});

export default function Input(props) {
  var {
    labelInput = '',
    isLoading,
    selectedElement,
    setSelectedElement,
    name,
    disabled,
    sx = {},
  } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(selectedElement);
  const theme = useTheme();

  useEffect(() => {
    if (selectedElement) {
      setInputValue(selectedElement);
    } else {
      setInputValue('');
    }
  }, [selectedElement]);

  return (
    <FormControl variant="standard" sx={{ m: 1, ...sx }}>
      <StyledTextField
        label={t(labelInput)}
        value={inputValue}
        onChange={event => {
          setInputValue(event.target.value);
        }}
        onKeyPress={event =>
          (inputValue.length > 3 || inputValue.length === 0) &&
            event.key === 'Enter'
            ? setSelectedElement(name, inputValue)
            : null
        }
        disabled={disabled}
        id="outlined-end-adornment"
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                disabled={disabled}
                onClick={e =>
                  inputValue.length > 3 || inputValue.length === 0
                    ? setSelectedElement(name, inputValue)
                    : null
                }
                edge="end"
                sx={{
                  ':hover': {
                    backgroundColor:
                      theme.palette.type === 'dark'
                        ? theme.palette.primary.main
                        : theme.palette.grey[400],
                  },
                }}>
                <IconComponent
                  iconName={'search'}
                  className={'icon'}
                  style={{
                    color:
                      theme.palette.type === 'dark'
                        ? 'white'
                        : theme.palette.primary.main,
                    height: '2.3vh',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {isLoading && (
        <LinearProgress
          sx={{
            width: '98%',
            mx: 'auto',
            backgroundColor: theme.palette.background.default,
          }}
        />
      )}
    </FormControl>
  );
}
