import { createReducer } from './utils';

import { SNACK_ALERT_SET } from './constants';

// TODO: delete this snackAlert and replace it with the one in zippediToolsJS

const defaultState = {
  snackAlert: { open: false, severity: null, message: null },
};

export const reducer = createReducer(defaultState, {
  [SNACK_ALERT_SET]: handleSnackSet,
});

function handleSnackSet(state, { payload: { snackAlert } }) {
  return {
    ...state,
    snackAlert,
  };
}

export function setSnackAlert(snackAlert) {
  return async dispatch => {
    dispatch({
      type: SNACK_ALERT_SET,
      payload: { snackAlert },
    });
  };
}
