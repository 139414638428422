import { createReducer } from './utils';

import {
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
} from './constants';

const defaultState = {
  loading: false,
  countries: null,
  errorMessage: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [GET_COUNTRIES_REQUEST]: handleGetCountriesRequest,
  [GET_COUNTRIES_SUCCESS]: handleGetCountriesSuccess,
  [GET_COUNTRIES_FAILURE]: handleGetCountriesFailure,
});

function handleGetCountriesRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetCountriesSuccess(state, { payload: { countries } }) {
  return {
    ...state,
    countries,
    loading: false,
    errorMessage: null,
  };
}

function handleGetCountriesFailure(state, { payload: { error } }) {
  return {
    loading: false,
    countries: [],
    errorMessage: error,
  };
}

// Actions

export function getCountries() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_COUNTRIES_REQUEST });
    try {
      const countries = await dataSource.getCountries();
      dispatch({
        type: GET_COUNTRIES_SUCCESS,
        payload: {
          countries,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_COUNTRIES_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}
