import { connect } from 'react-redux';
import { signSecondaryImage } from 'core/slices/images';
import {
  getSecondaryImageUrl,
  isLoadingSecondaryImages,
  getSecondaryImagesList,
} from 'core/selectors';
import DetectionModal from '../../../components/visualizer/DetectionModal';

export default connect(
  state => ({
    image_url: getSecondaryImageUrl(state),
    isLoadingImages: isLoadingSecondaryImages(state),
    images: getSecondaryImagesList(state),
  }),
  { signSecondaryImage },
)(DetectionModal);
