import { connect } from 'react-redux';
import {
  isLoadingImages,
  isLoadingSecondaryImages,
  isLoadingImageUrl,
} from 'core/selectors';
import Canvas from '../../../components/visualizer/Canvas';

export default connect(
  state => ({
    isLoadingImages: isLoadingImages(state),
    isLoadingImageUrl: isLoadingImageUrl(state),
    isLoadingSecondaryImages: isLoadingSecondaryImages(state),
  }),
  {},
)(Canvas);
