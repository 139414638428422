import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducer as sessionReducer } from './slices/session';
import { reducer as countriesReducer } from './slices/countries';
import { reducer as chainsReducer } from './slices/chains';
import { reducer as storesReducer } from './slices/stores';
import { reducer as sessionsReducer } from './slices/sessions';
import { reducer as aislesReducer } from './slices/aisles';
import { reducer as imagesReducer } from './slices/images';
import { reducer as shareStateReducer } from './slices/shareState';
import { reducer as detectionsReducer } from './slices/detections';
import { reducer as snackAlertReducer } from './slices/snackAlert';

export default function _createStore(services) {
  return createStore(
    combineReducers({
      session: sessionReducer,
      countries: countriesReducer,
      chains: chainsReducer,
      stores: storesReducer,
      sessions: sessionsReducer,
      aisles: aislesReducer,
      images: imagesReducer,
      shareState: shareStateReducer,
      detections: detectionsReducer,
      snackAlert: snackAlertReducer,
    }),
    applyMiddleware(thunk.withExtraArgument({ services })),
  );
}
