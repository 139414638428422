import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export default function StyledPaper(props) {
    const { children, inputBackground = 'white', Inputcolor } = props

    const StyledPaper = styled(props => {
        return (
            <Paper
                {...props}
            />
        )
    }
    )(({ theme }) => {
        const lightBackground = inputBackground ? inputBackground : Object.values(theme.palette[inputBackground])[0];
        return ({
            background: theme.palette.type === 'light' ? lightBackground : theme.palette.paper.main,
            color: Inputcolor ? Inputcolor : theme.palette.type === 'light' ? theme.palette.primary.main + 'D9' : 'rgba(255, 255, 255, 0.87)',
        })
    });

    return (
        <StyledPaper {...props}> {children} </StyledPaper>
    );
}