import { connect } from 'react-redux';
import { logOut } from 'core/slices/session';
import { userInfo } from 'core/selectors';
import MyMenu from '../../../components/navbar/MyMenu';

export default connect(
  state => ({
    userInfo: userInfo(state),
  }),
  { logOut },
)(MyMenu);
