export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export const CREDENTIALS_AVAILABLE = 'CREDENTIALS_AVAILABLE';
export const CREDENTIALS_UNAVAILABLE = 'CREDENTIALS_UNAVAILABLE';
export const CREDENTIALS_STORE = 'CREDENTIALS_STORE';
export const CREDENTIALS_CLEAR = 'CREDENTIALS_CLEAR';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const GET_CHAINS_REQUEST = 'GET_CHAINS_REQUEST';
export const GET_CHAINS_SUCCESS = 'GET_CHAINS_SUCCESS';
export const GET_CHAINS_FAILURE = 'GET_CHAINS_FAILURE';

export const GET_STORES_REQUEST = 'GET_STORES_REQUEST';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';
export const GET_STORES_RESET = 'GET_STORES_RESET';

export const GET_SESSIONS_REQUEST = 'GET_SESSIONS_REQUEST';
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_FAILURE = 'GET_SESSIONS_FAILURE';
export const GET_SESSIONS_RESET = 'GET_SESSIONS_RESET';

export const GET_AISLES_REQUEST = 'GET_AISLES_REQUEST';
export const GET_AISLES_SUCCESS = 'GET_AISLES_SUCCESS';
export const GET_AISLES_FAILURE = 'GET_AISLES_FAILURE';

export const GET_IMAGES_REQUEST = 'GET_IMAGES_REQUEST';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAILURE = 'GET_IMAGES_FAILURE';
export const GET_IMAGES_RESET = 'GET_IMAGES_RESET';
export const GET_IMAGES_URL_SUCCESS = 'GET_IMAGES_URL_SUCCESS';
export const GET_IMAGES_URL_FAILURE = 'GET_IMAGES_URL_FAILURE';
export const GET_IMAGES_URL_REQUEST = 'GET_IMAGES_URL_REQUEST';
export const GET_IMAGES_URL_RESET = 'GET_IMAGES_URL_RESET';
export const GET_SECONDARY_IMAGES_REQUEST = 'GET_SECONDARY_IMAGES_REQUEST';
export const GET_SECONDARY_IMAGES_SUCCESS = 'GET_SECONDARY_IMAGES_SUCCESS';
export const GET_SECONDARY_IMAGES_FAILURE = 'GET_SECONDARY_IMAGES_FAILURE';
export const GET_SECONDARY_IMAGES_RESET = 'GET_SECONDARY_IMAGES_RESET';
export const GET_SECONDARY_IMAGES_URL_SUCCESS = 'GET_SECONDARY_IMAGES_URL_SUCCESS';
export const GET_SECONDARY_IMAGES_URL_FAILURE = 'GET_SECONDARY_IMAGES_URL_FAILURE';
export const GET_SECONDARY_IMAGES_URL_REQUEST = 'GET_SECONDARY_IMAGES_URL_REQUEST';
export const GET_SECONDARY_IMAGES_URL_RESET = 'GET_SECONDARY_IMAGES_URL_RESET';

export const SET_SHARE_STATE = 'SET_SHARE_STATE';

export const GET_DETECTIONS_REQUEST = 'GET_DETECTIONS_REQUEST';
export const GET_DETECTIONS_SUCCESS = 'GET_DETECTIONS_SUCCESS';
export const GET_DETECTIONS_FAILURE = 'GET_DETECTIONS_FAILURE';
export const CLEAR_DETECTIONS = 'CLEAR_DETECTIONS';

export const SNACK_ALERT_SET = 'SNACK_ALERT_SET';