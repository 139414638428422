import { LinearProgress, FormControl, Paper, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { lighten, darken } from '@mui/system';

const StyledTextField = styled(props => {
  return <TextField {...props} />;
})(({ theme }) => {
  return {
    '& .MuiInputLabel-root': {
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiFilledInput-root': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiOutlinedInput-root': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)',
    },
    '.MuiFormLabel-root.Mui-disabled': {
      color:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(255, 255, 255, 0.17)'
          : theme.palette.text.primary,
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.17)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '& > fieldset': {
        borderColor:
          theme.palette.type === 'light'
            ? 'rgba(0, 0, 0, 0.13)'
            : 'rgba(255, 255, 255, 0.13)',
      },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
    },
    '& .MuiAutocomplete-popupIndicator': {
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiAutocomplete-popupIndicator.Mui-disabled': {
      color:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
    },
    '& 	.MuiAutocomplete-clearIndicator': {
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
  };
});

export default function Selector(props) {
  const theme = useTheme();
  var {
    labelInput = '',
    options = [],
    optionLabel = 'name',
    isLoading,
    selectedElement,
    setSelectedElement,
    name,
    valueLabel,
    disabled,
    required,
    defaultValue,
    groupLabel,
    secondaryValueLabel,
    disableClearable = false,
    sx = {},
  } = props;
  const { t } = useTranslation();

  // In case options is an array of strings
  if (options && typeof options[0] === 'string') {
    options = options.map(option => ({
      [optionLabel]: option,
      [valueLabel]: option,
    }));
  }
  if (options && secondaryValueLabel) {
    options.sort((a, b) => {
      if (a[optionLabel] < b[optionLabel]) {
        return 1;
      }
      if (a[optionLabel] > b[optionLabel]) {
        return -1;
      }
      return 0;
    });
  }

  return (
    <FormControl variant="standard" size="small" sx={{ m: 1, ...sx }}>
      <Autocomplete
        blurOnSelect
        disableClearable={disableClearable}
        value={
          options.find(option => option[valueLabel] === selectedElement) || null
        }
        onChange={(event, newValue) => {
          if (newValue === null) {
            setSelectedElement(name, null, null);
          } else {
            setSelectedElement(
              name,
              newValue[valueLabel],
              newValue[secondaryValueLabel],
            );
          }
        }}
        id="controllable-selector"
        options={options}
        disabled={disabled}
        getOptionLabel={option => option[optionLabel]}
        defaultValue={defaultValue ? options[0] : null}
        groupBy={groupLabel ? option => option[groupLabel] : null}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              background:
                theme.palette.type === 'light'
                  ? 'white'
                  : theme.palette.paper.main,
              color:
                theme.palette.type === 'light'
                  ? theme.palette.text.primary
                  : 'white',
            }}>
            {children}
          </Paper>
        )}
        renderInput={params => (
          <StyledTextField
            {...params}
            required={required}
            variant="outlined"
            label={t(labelInput)}
          />
        )}
        renderGroup={params => (
          <div>
            <div
              style={{
                position: 'sticky',
                top: '-8px',
                padding: '4px 10px',
                color: theme.palette.primary.main,
                backgroundColor:
                  theme.palette.type === 'light'
                    ? lighten(theme.palette.paper.main, 0.1)
                    : darken(theme.palette.paper.main, 0.1),
              }}>
              {params.group}
            </div>
            {params.children}
          </div>
        )}
      />
      {isLoading && (
        <LinearProgress
          sx={{
            width: '98%',
            mx: 'auto',
            backgroundColor: theme.palette.background.default,
          }}
        />
      )}
    </FormControl>
  );
}
