import { connect } from 'react-redux';
import { getCountries } from 'core/slices/countries';
import { getChains } from 'core/slices/chains';
import { getStores, cleanStores } from 'core/slices/stores';
import { getSessions, cleanSessions } from 'core/slices/sessions';
import { getAisles } from 'core/slices/aisles';
import { getDetectionsByValue, clearDetections } from 'core/slices/detections';
import { setSnackAlert } from 'core/slices/snackAlert';
import {
  getImages,
  signImage,
  cleanImage,
  cleanImages,
} from 'core/slices/images';
import {
  getCountriesList,
  getChainsList,
  getStoresList,
  getSessionsList,
  getAislesList,
  getImagesList,
  isLoadingCountries,
  isLoadingChains,
  isLoadingStores,
  isLoadingSessions,
  isLoadingAisles,
  isLoadingImages,
  getShareState,
  getDetectionsList,
  isLoadingDetections,
} from 'core/selectors';
import TopMenu from '../../../components/visualizer/TopMenu';

export default connect(
  state => ({
    countries: getCountriesList(state),
    chains: getChainsList(state),
    stores: getStoresList(state),
    sessions: getSessionsList(state),
    aisles: getAislesList(state),
    images: getImagesList(state),
    isLoadingCountries: isLoadingCountries(state),
    isLoadingChains: isLoadingChains(state),
    isLoadingStores: isLoadingStores(state),
    isLoadingSessions: isLoadingSessions(state),
    isLoadingAisles: isLoadingAisles(state),
    isLoadingImages: isLoadingImages(state),
    recievedState: getShareState(state),
    detections: getDetectionsList(state),
    isLoadingDetections: isLoadingDetections(state),
  }),
  {
    getCountries,
    getChains,
    getStores,
    getSessions,
    getAisles,
    getImages,
    signImage,
    cleanImage,
    cleanImages,
    cleanSessions,
    cleanStores,
    getDetectionsByValue,
    clearDetections,
    setSnackAlert,
  },
)(TopMenu);
