import { createReducer } from './utils';

import {
  GET_SESSIONS_REQUEST,
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_RESET,
} from './constants';

const defaultState = {
  loading: false,
  sessions: null,
  errorMessage: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [GET_SESSIONS_REQUEST]: handleGetSessionsRequest,
  [GET_SESSIONS_SUCCESS]: handleGetSessionsSuccess,
  [GET_SESSIONS_FAILURE]: handleGetSessionsFailure,
  [GET_SESSIONS_RESET]: handleGetSessionsReset,
});

function handleGetSessionsRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetSessionsSuccess(state, { payload: { sessions } }) {
  return {
    ...state,
    sessions,
    loading: false,
    errorMessage: null,
  };
}

function handleGetSessionsFailure(state, { payload: { error } }) {
  return {
    loading: false,
    sessions: [],
    errorMessage: error,
  };
}

function handleGetSessionsReset(state) {
  return {
    ...state,
    loading: false,
    sessions: null,
    errorMessage: null,
  };
}

// Actions

export function getSessions(store, useBifrost) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SESSIONS_REQUEST });
    try {
      const sessions = await dataSource.getSessions(store, useBifrost);
      dispatch({
        type: GET_SESSIONS_SUCCESS,
        payload: {
          sessions,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SESSIONS_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}

export function cleanSessions() {
  return async dispatch => {
    dispatch({
      type: GET_SESSIONS_RESET,
    });
  };
}
