import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

export default function Home() {
  let navigate = useNavigate();
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={4} sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("../");
          }}
        >
          Navigate back!
        </Button>
      </Grid>
    </Grid>
  );
}
