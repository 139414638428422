import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import '../App.css';

// Zippedi tools js imports
import { ReduxLoginWeb as PrivateRoute } from '@zippeditoolsjs/web';
import { lightTheme, darkTheme } from '@zippeditoolsjs/web';

import Home from './redux/containers/home/Home';
import Search from './redux/containers/search/Search';
import Layout from './components/navbar/Layout';
import Share from './redux/containers/visualizer/Share';
import SnackAlert from './redux/containers/tools/SnackAlert';

// Instantiate services and wire up dependencies
import createStore from 'core/store';
import HTTPService from 'core/services/http';
import DataSource from 'core/services/dataSource';
import AuthSource from 'core/services/authSource';

import { useTranslation, withTranslation } from 'react-i18next';
import Visualizer from './redux/containers/visualizer/Visualizer';

const { REACT_APP_API_BASE_URL, REACT_APP_AUTH_BASE_URL, REACT_APP_BASE_URL, REACT_APP_AUTH_BACKEND_URL } =
  process.env;

const http = new HTTPService();

const dataSource = new DataSource({
  baseUrl: REACT_APP_API_BASE_URL,
  http,
});

const authSource = new AuthSource({
  baseUrl: REACT_APP_AUTH_BACKEND_URL,
  apiUrl: REACT_APP_API_BASE_URL,
  http,
});

const store = createStore({
  authSource,
  dataSource,
});

const App = () => {
  const [theme, setTheme] = useState('dark');
  const isDarkTheme = theme === 'dark';
  const toggleTheme = () => setTheme(isDarkTheme ? 'light' : 'dark');

  const { i18n } = useTranslation();
  const toogleTranslation = lang => i18n.changeLanguage(lang);

  return (
    <Provider store={store}>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <SnackAlert />
        <PrivateRoute
          appUrlLogin={REACT_APP_AUTH_BASE_URL}
          appBaseUrl={REACT_APP_BASE_URL}>
          <Layout
            isDarkTheme={isDarkTheme}
            toggleTheme={toggleTheme}
            toogleTranslation={toogleTranslation}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/search" element={<Search />} />
                <Route path="/share" element={<Share />} />
                <Route path="/visualizer" element={<Visualizer />} />
              </Routes>
            </BrowserRouter>
          </Layout>
        </PrivateRoute>
      </ThemeProvider>
    </Provider>
  );
};

export default withTranslation()(App);
