import { connect } from 'react-redux';
import {
  getSecondaryImages,
  signImage,
  cleanImage,
  cleanSecondaryImage,
  cleanSecondaryImages,
} from 'core/slices/images';
import { getImagesList, getImageUrl } from 'core/selectors';
import Visualizer from '../../../components/visualizer/Visualizer';

export default connect(
  state => ({
    images: getImagesList(state),
    image_url: getImageUrl(state),
  }),
  {
    getSecondaryImages,
    signImage,
    cleanImage,
    cleanSecondaryImage,
    cleanSecondaryImages,
  },
)(Visualizer);
