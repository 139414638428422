import { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Share = props => {
  const [recievedView, setRecievedView] = useState(null);
  const [recievedState, setRecievedState] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // TODO: refactor to standarize like the PageLink.js in CWS APP and move from visualizer folder
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    var state = urlParams.get('state');
    const view = urlParams.get('view');

    if (view && recievedView === null) {
      setRecievedView(view);
    }
    if (state && recievedState === null) {
      try {
        const decodedState = Buffer.from(state, 'base64').toString('utf8');
        state = JSON.parse(decodedState);
        setRecievedState(state);
        props.setShareState(state);
        if (view === 'visualizer') {
          navigate('/visualizer');
        }
      } catch (error) {
        return <div>{t('hip_app.general.invalid_state')}</div>;
      }
    }
  }, []);
  if (recievedState) {
    if (recievedView !== 'visualizer') {
      return <div>{t('hip_app.general.invalid_view')}</div>;
    }
  } else {
    return <div>{t('hip_app.general.invalid_state')}</div>;
  }
};

export default Share;
