import { createReducer } from './utils';

import {
  GET_DETECTIONS_REQUEST,
  GET_DETECTIONS_SUCCESS,
  GET_DETECTIONS_FAILURE,
  CLEAR_DETECTIONS,
} from './constants';

const defaultState = {
  loading: false,
  detections: null,
  errorMessage: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [GET_DETECTIONS_REQUEST]: handleGetDetectionsRequest,
  [GET_DETECTIONS_SUCCESS]: handleGetDetectionsSuccess,
  [GET_DETECTIONS_FAILURE]: handleGetDetectionsFailure,
  [CLEAR_DETECTIONS]: () => defaultState,
});

function handleGetDetectionsRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetDetectionsSuccess(state, { payload: { detections } }) {
  return {
    ...state,
    detections,
    loading: false,
    errorMessage: null,
  };
}

function handleGetDetectionsFailure(state, { payload: { error } }) {
  return {
    loading: false,
    detections: [],
    errorMessage: error,
  };
}

// Actions

export function getDetectionsByValue(payload) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_DETECTIONS_REQUEST });
    try {
      const detections = await dataSource.getDetectionsByValue(payload);
      dispatch({
        type: GET_DETECTIONS_SUCCESS,
        payload: {
          detections,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DETECTIONS_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}

export function clearDetections() {
  return {
    type: CLEAR_DETECTIONS,
  };
}
