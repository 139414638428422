export default function DataSourceService({ baseUrl, http }) {
  const PATH_GENERIC_INFO = '/api/info';
  const PATH_COUNTRIES = '/api/locations/countries';
  const PATH_CHAINS = '/api/locations/chains';
  const PATH_STORES = '/api/locations/stores';
  const PATH_SESSIONS = '/api/ppl/sessions/list';
  const PATH_AISLES = '/api/ppl/aisle';
  const PATH_IMAGES = '/api/visualize/images_metadata';
  const SIGN_IMAGE = '/api/visualize/sign_image';
  const PATH_DETECTIONS_BY_VALUE = '/api/visualize/detection_id_by_value';

  return {
    getGenericInfo,
    getCountries,
    getChains,
    getStores,
    getSessions,
    getAisles,
    getImages,
    signImage,
    getDetectionsByValue,
  };

  function getGenericInfo({ info }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_GENERIC_INFO}`,
      body: {
        info,
      },
    });
  }

  function getCountries() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_COUNTRIES}`,
    });
  }

  function getChains() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CHAINS}`,
    });
  }

  function getStores(payload) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STORES}`,
      query: {
        country_id: payload.country_id,
        chain_id: payload.chain_id,
      },
    });
  }

  function getSessions(store, useBifrost) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}/${store}/${useBifrost}`,
    });
  }

  function getAisles(session, store, useBifrost) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_AISLES}/${session}/${useBifrost}/${store}`,
    });
  }

  function getImages(payload) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_IMAGES}`,
      body: payload,
    });
  }

  function signImage(payload) {
    return http.postAuthenticated({
      url: `${baseUrl}${SIGN_IMAGE}`,
      body: payload,
    });
  }

  function getDetectionsByValue(payload) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DETECTIONS_BY_VALUE}`,
      body: payload,
    });
  }
}
