import { createReducer } from './utils';

import {
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_STORES_FAILURE,
  GET_STORES_RESET,
} from './constants';

const defaultState = {
  loading: false,
  stores: null,
  errorMessage: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [GET_STORES_REQUEST]: handleGetStoresRequest,
  [GET_STORES_SUCCESS]: handleGetStoresSuccess,
  [GET_STORES_FAILURE]: handleGetStoresFailure,
  [GET_STORES_RESET]: handleGetStoresReset,
});

function handleGetStoresRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetStoresSuccess(state, { payload: { stores } }) {
  return {
    ...state,
    stores,
    loading: false,
    errorMessage: null,
  };
}

function handleGetStoresFailure(state, { payload: { error } }) {
  return {
    loading: false,
    stores: [],
    errorMessage: error,
  };
}

function handleGetStoresReset(state) {
  return {
    ...state,
    loading: false,
    stores: null,
    errorMessage: null,
  };
}
// Actions

export function getStores(payload) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_STORES_REQUEST });
    try {
      const stores = await dataSource.getStores(payload);
      dispatch({
        type: GET_STORES_SUCCESS,
        payload: {
          stores,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_STORES_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}

export function cleanStores() {
  return async dispatch => {
    dispatch({
      type: GET_STORES_RESET,
    });
  };
}
