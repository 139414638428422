import { createReducer } from './utils';

import {
  GET_AISLES_REQUEST,
  GET_AISLES_SUCCESS,
  GET_AISLES_FAILURE,
} from './constants';

const defaultState = {
  loading: false,
  aisles: null,
  errorMessage: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [GET_AISLES_REQUEST]: handleGetAislesRequest,
  [GET_AISLES_SUCCESS]: handleGetAislesSuccess,
  [GET_AISLES_FAILURE]: handleGetAislesFailure,
});

function handleGetAislesRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetAislesSuccess(state, { payload: { aisles } }) {
  return {
    ...state,
    aisles,
    loading: false,
    errorMessage: null,
  };
}

function handleGetAislesFailure(state, { payload: { error } }) {
  return {
    loading: false,
    aisles: [],
    errorMessage: error,
  };
}

// Actions

export function getAisles(session, store, useBifrost) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_AISLES_REQUEST });
    try {
      const aisles = await dataSource.getAisles(session, store, useBifrost);
      dispatch({
        type: GET_AISLES_SUCCESS,
        payload: {
          aisles,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_AISLES_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}
