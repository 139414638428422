import { createReducer } from './utils';
import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
} from './constants';

const defaultState = {
  loading: true,
  loadingLogOut: false,
  loggedIn: false,
  errorMessage: null,
  userInfo: {},
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [SIGN_IN_REQUEST]: handleSignInRequest,
  [SIGN_IN_SUCCESS]: handleSignInSuccess,
  [SIGN_IN_FAILURE]: handleSignInFailure,
  [LOG_OUT_REQUEST]: handleLogOutRequest,
  [LOG_OUT_SUCCESS]: handleLogOutSuccess,
  [LOG_OUT_FAILURE]: handleLogOutFailure,
});

function handleSignInRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleSignInSuccess(state, { payload: { userInfo } }) {
  return {
    ...state,
    userInfo,
    loading: false,
    loggedIn: true,
    errorMessage: null,
  };
}

function handleSignInFailure(state, { payload: { error } }) {
  return {
    loading: false,
    loggedIn: false,
    errorMessage: error,
    userInfo: {},
  };
}

function handleLogOutRequest(state) {
  return {
    ...state,
    loadingLogOut: true,
  };
}

function handleLogOutSuccess(state) {
  return {
    ...state,
    loading: false,
    loadingLogOut: false,
    loggedIn: false,
    errorMessage: null,
    userInfo: {},
  };
}

function handleLogOutFailure(state, { payload: { error } }) {
  return {
    loadingLogOut: false,
    errorMessage: error,
  };
}

// Actions

export function checkSession() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: SIGN_IN_REQUEST });
    try {
      const userInfo = await authSource.checkAuthenticated();
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: { userInfo },
      });
    } catch (error) {
      dispatch({
        type: SIGN_IN_FAILURE,
        payload: { error },
      });
    }
  };
}

export function logOut() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: LOG_OUT_REQUEST });
    try {
      await authSource.logOut();
      dispatch({
        type: LOG_OUT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: LOG_OUT_FAILURE,
        payload: { error },
      });
    }
  };
}
