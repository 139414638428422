import { createReducer } from './utils';

import {
  GET_CHAINS_REQUEST,
  GET_CHAINS_SUCCESS,
  GET_CHAINS_FAILURE,
} from './constants';

const defaultState = {
  loading: false,
  chains: null,
  errorMessage: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [GET_CHAINS_REQUEST]: handleGetChainsRequest,
  [GET_CHAINS_SUCCESS]: handleGetChainsSuccess,
  [GET_CHAINS_FAILURE]: handleGetChainsFailure,
});

function handleGetChainsRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetChainsSuccess(state, { payload: { chains } }) {
  return {
    ...state,
    chains,
    loading: false,
    errorMessage: null,
  };
}

function handleGetChainsFailure(state, { payload: { error } }) {
  return {
    loading: false,
    chains: [],
    errorMessage: error,
  };
}

// Actions

export function getChains() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_CHAINS_REQUEST });
    try {
      const chains = await dataSource.getChains();
      dispatch({
        type: GET_CHAINS_SUCCESS,
        payload: {
          chains,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_CHAINS_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}
