export default function AuthSourceService({ baseUrl, apiUrl, http }) {
  const PATH_CHECK_USER = '/api/info/user';
  const PATH_LOG_OUT = '/sessionLogout';

  return {
    checkAuthenticated,
    logOut,
  };

  function checkAuthenticated() {
    return http.getAuthenticated({
      url: `${apiUrl}${PATH_CHECK_USER}`,
    });
  }

  function logOut() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_LOG_OUT}`,
    });
  }
}
