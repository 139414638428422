export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function userInfo({ session: { userInfo } }) {
  return userInfo;
}

export function hasSession({ session: { loggedIn } }) {
  return loggedIn;
}

export function getCountriesList({ countries: { countries } }) {
  return countries;
}

export function isLoadingCountries({ countries: { loading } }) {
  return loading;
}

export function getChainsList({ chains: { chains } }) {
  return chains;
}

export function isLoadingChains({ chains: { loading } }) {
  return loading;
}

export function getStoresList({ stores: { stores } }) {
  return stores;
}

export function isLoadingStores({ stores: { loading } }) {
  return loading;
}

export function getSessionsList({ sessions: { sessions } }) {
  return sessions;
}

export function isLoadingSessions({ sessions: { loading } }) {
  return loading;
}

export function getAislesList({ aisles: { aisles } }) {
  return aisles;
}

export function isLoadingAisles({ aisles: { loading } }) {
  return loading;
}

export function getImagesList({ images: { images } }) {
  return images;
}

export function getSecondaryImagesList({ images: { secondary_images } }) {
  return secondary_images;
}

export function isLoadingImages({ images: { loading } }) {
  return loading;
}
export function isLoadingImageUrl({ images: { loading_url } }) {
  return loading_url;
}

export function isLoadingSecondaryImages({ images: { loading_secondary } }) {
  return loading_secondary;
}

export function getImageUrl({ images: { image_url } }) {
  return image_url;
}

export function getSecondaryImageUrl({ images: { secondary_image_url } }) {
  return secondary_image_url;
}

export function getShareState({ shareState: { recievedState } }) {
  return recievedState;
}

export function getDetectionsList({ detections: { detections } }) {
  return detections;
}

export function isLoadingDetections({ detections: { loading } }) {
  return loading;
}

export function getSnackAlert({ snackAlert: { snackAlert } }) {
  return snackAlert;
}
