import { connect } from 'react-redux';
import { setSnackAlert } from 'core/slices/snackAlert';
import { getSnackAlert } from 'core/selectors';
import SnackAlert from '../../../components/tools/SnackAlert';

export default connect(
  state => ({
    snackAlert: getSnackAlert(state),
  }),
  {
    setSnackAlert,
  },
)(SnackAlert);
