import { createReducer } from './utils';

import { SET_SHARE_STATE } from './constants';

const defaultState = {
  recievedState: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [SET_SHARE_STATE]: handleSetShareState,
});

function handleSetShareState(state, { payload: { recievedState } }) {
  return {
    ...state,
    recievedState,
  };
}

// Actions

export function setShareState(recievedState) {
  return {
    type: SET_SHARE_STATE,
    payload: {
      recievedState,
    },
  };
}
