import {tab} from "@testing-library/user-event/dist/tab";

export const FIXTURES_DISPLAY = {
  0: {
    color: "#0097e6",
    display_name: "On shelf",
  },
  1: {
    color: "#8c7ae6",
    display_name: "Hook",
  },
  2: {
    color: "#e1b12c",
    display_name: "Cut case",
  },
  3: {
    color: "#44bd32",
    display_name: "On floor",
  },
}

export const drawLabels = async (
  metadata,
  imagecanvas,
  theme,
  factor = 1,
  selectedLabel,
) => {
  const canvas = document.getElementById(imagecanvas);
  const ctx = canvas.getContext('2d');
  metadata.forEach(label => {
    ctx.lineWidth = '6';
    ctx.strokeStyle = theme.palette.secondary.main;
    ctx.beginPath();
    if (
      label.x1 !== undefined &&
      label.y1 !== undefined &&
      label.x2 !== undefined &&
      label.y2 !== undefined &&
      (!selectedLabel ||
        (selectedLabel && label.detection_id === selectedLabel))
    ) {
      ctx.moveTo(label.x1 * factor, label.y1 * factor); // Bottom left
      ctx.lineTo(label.x1 * factor, label.y2 * factor); // Top left
      ctx.lineTo(label.x2 * factor, label.y2 * factor); // Top right
      ctx.lineTo(label.x2 * factor, label.y1 * factor); // Bottom right
    }
    ctx.closePath();
    ctx.stroke();
    ctx.font = '100% ' + theme.typography.fontFamily;
    ctx.fillStyle = theme.palette.secondary.main;
    if (
      label.score &&
      (!selectedLabel ||
        (selectedLabel && label.detection_id === selectedLabel))
    ) {
      ctx.fillText(
        (label.score * 100).toFixed(2) + '%',
        label.x1 * factor,
        label.y1 * factor - 5,
      );
    }
  });
};

export const drawFacings = async (metadata, imagecanvas, theme, canShowPositionPrediction, factor = 1) => {
  const canvas = document.getElementById(imagecanvas);
  const ctx = canvas.getContext('2d');
  metadata.forEach(facing => {
    ctx.lineWidth = '6';
    ctx.strokeStyle = theme.palette.secondary.main;
    ctx.beginPath();
    if (facing.prediction_bbox) {
      ctx.moveTo(
        facing.prediction_bbox[0] * factor,
        facing.prediction_bbox[1] * factor,
      ); // Bottom left
      ctx.lineTo(
        facing.prediction_bbox[0] * factor,
        facing.prediction_bbox[3] * factor,
      ); // Top left
      ctx.lineTo(
        facing.prediction_bbox[2] * factor,
        facing.prediction_bbox[3] * factor,
      ); // Top right
      ctx.lineTo(
        facing.prediction_bbox[2] * factor,
        facing.prediction_bbox[1] * factor,
      ); // Bottom right
    }
    ctx.closePath();
    ctx.stroke();
    if (facing["position_prediction.bbox"] && canShowPositionPrediction) {
      ctx.lineWidth = '2';
      ctx.strokeStyle = FIXTURES_DISPLAY[facing["position_prediction.fixture_id"]].color;
      ctx.beginPath();
      const bbox = facing["position_prediction.bbox"];

      ctx.moveTo(bbox[0][0], bbox[0][1]);
      for (let i = 1; i < bbox.length; i++) {
        ctx.lineTo(bbox[i][0], bbox[i][1]);
      }
      ctx.closePath();
      ctx.stroke();

    }

    ctx.font = '100% ' + theme.typography.fontFamily;
    ctx.fillStyle = theme.palette.secondary.main;
    if (facing.prediction_score) {
      ctx.fillText(
        (facing.prediction_score * 100).toFixed(2) + '%',
        facing.prediction_bbox[0] * factor,
        facing.prediction_bbox[1] * factor - 5,
      );
    }
  });
};

export const drawLabelReadings = async (
  metadata,
  currencyFormat,
  format,
  imagecanvas,
  theme,
  disableText,
  showLabelText,
  factor = 1,
  readingCanvas,
  chain,
  splitView,
  stage = "results",
) => {
  const {
    currency_symbol,
    currency_thousands_sep,
    currency_decimal_sep,
    currency_decimals,
  } = currencyFormat;
  const canvas = document.getElementById(imagecanvas);
  const ctx = canvas.getContext('2d');
  const margin = 10;
  metadata.forEach(label => {
    const {
      prediction_type,
      confidence,
      second_stage_reading,
      reading_stage,
      parsed_data,
    } = label;
    
    const canShowSecondStage = stage === "second_stage";
    
    const {
      bbox_coords,
      value
    } = canShowSecondStage ? second_stage_reading : label;

    if (bbox_coords?.length > 0 && value != null && (stage === 'results' || canShowSecondStage)) {
      if (prediction_type !== 3) {
        ctx.lineWidth = '3';
        ctx.strokeStyle = theme.palette.secondary.main;
        if (!readingCanvas && bbox_coords && bbox_coords.length > 0) {
          ctx.beginPath();
          ctx.moveTo(
            bbox_coords[0][0] * factor,
            bbox_coords[0][1] * factor,
          );
          ctx.lineTo(
            bbox_coords[1][0] * factor,
            bbox_coords[1][1] * factor,
          );
          ctx.lineTo(
            bbox_coords[2][0] * factor,
            bbox_coords[2][1] * factor,
          );
          ctx.lineTo(
            bbox_coords[3][0] * factor,
            bbox_coords[3][1] * factor,
          );
          ctx.closePath();
          ctx.stroke();
        }
        ctx.font = '100% ' + theme.typography.fontFamily;
        ctx.fillStyle = theme.palette.secondary.main;
        var labelNumber = value;
        if (
          bbox_coords &&
          bbox_coords.length > 0 &&
          bbox_coords[1][0] * factor > bbox_coords[0][0] * factor
        ) {
          ctx.save();
          const angle = Math.atan2(
            bbox_coords[1][1] * factor - bbox_coords[0][1] * factor,
            bbox_coords[1][0] * factor - bbox_coords[0][0] * factor,
          );
          if (!disableText && readingCanvas) {
            if (prediction_type === 0) {
              var numberFormated = labelNumber;
              if (format) {
                numberFormated = formatCurrency(
                  labelNumber,
                  currency_symbol,
                  currency_thousands_sep,
                  currency_decimal_sep,
                  currency_decimals,
                );
              }
              const x1 = bbox_coords[0][0] * factor;
              const x2 = bbox_coords[2][0] * factor;
              const y1 = bbox_coords[0][1] * factor;
              const y2 = bbox_coords[2][1] * factor;
              // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
              let textWidth = ctx.measureText(numberFormated).width;
              let textHeight = ctx.measureText('m').width;
              if (angle > 1) {
                textHeight = ctx.measureText('em').width;
              }
              let words = [];
              if (chain === 7) {
                // Home Depot workaround
                words = numberFormated.split('.');
                textWidth = ctx.measureText(words[0]).width;
              }
              ctx.translate(x1, y1);
              ctx.rotate(angle);
              const dx = x2 - x1;
              const dy = y2 - y1;
              const scale = dx / textWidth;
              const scaleY = dy / textHeight;

              if (scale > 1) {
                ctx.scale(currency_decimals ? scale : scale, scaleY);
              } else {
                ctx.scale(1, scaleY);
              }
              if (chain === 7) {
                // Home Depot workaround
                if (angle > 1) {
                  ctx.fillText(words[0], 0, textHeight / 2);
                } else {
                  ctx.fillText(words[0], 0, textHeight);
                }
                ctx.scale(0.8, 0.5);
                if (angle > 1) {
                  ctx.fillText(words[1], textWidth * 1.2, textHeight / 2);
                } else {
                  ctx.fillText(words[1], textWidth * 1.2, textHeight);
                }
              } else {
                if (angle > 1) {
                  ctx.fillText(numberFormated, 0, textHeight / 2);
                } else {
                  ctx.fillText(numberFormated, 0, textHeight);
                }
              }
              ctx.restore();
            } else {
              const x1 = bbox_coords[0][0] * factor;
              const x2 = bbox_coords[2][0] * factor;
              const y1 = bbox_coords[0][1] * factor;
              const y2 = bbox_coords[2][1] * factor;
              const distance = Math.sqrt(
                Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2),
              );
              // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
              let measureString = 'm';
              let dividerFactor = 1;
              if (Math.abs(angle) > 0.04) {
                measureString = 'em';
                dividerFactor = 2;
              }
              const textWidth = ctx.measureText(labelNumber).width;
              let textHeight = ctx.measureText(measureString).width;
              ctx.translate(x1, y1);
              ctx.rotate(angle);
              const dy = Math.abs(y2 - y1);
              const scaleY = dy / textHeight;
              let scale = distance / textWidth;
              if (angle < 0) {
                scale = Math.abs(x2 - x1) / textWidth;
              }
              if (scaleY / scale > 0.5) {
                ctx.scale(scale, scaleY);
              } else {
                ctx.scale(scale, scale);
              }
              ctx.fillText(labelNumber, 0, textHeight / dividerFactor);
              ctx.restore();
            }
          }
          ctx.restore();
          if (confidence && !disableText && !readingCanvas && splitView) {
            ctx.save();
            ctx.translate(
              bbox_coords[0][0] * factor,
              bbox_coords[0][1] * factor - 5,
            );
            ctx.rotate(angle);
            ctx.font = '100% ' + theme.typography.fontFamily;
            const textWidth = ctx.measureText(
              (confidence * 100).toFixed(2) + '%',
            ).width;
            if (
              bbox_coords[1][0] * factor + textWidth >
              canvas.width - margin
            ) {
              ctx.translate(-textWidth, 30);
            }
            ctx.fillText((confidence * 100).toFixed(2) + '%', 0, 0);
            ctx.restore();
          }
          if (value && !splitView) {
            numberFormated = labelNumber;
            if (prediction_type === 0) {
              if (format) {
                numberFormated = formatCurrency(
                  labelNumber,
                  currency_symbol,
                  currency_thousands_sep,
                  currency_decimal_sep,
                  currency_decimals,
                );
              }
            }
            ctx.save();
            ctx.translate(
              bbox_coords[0][0] * factor,
              bbox_coords[0][1] * factor - 5,
            );
            ctx.rotate(angle);
            ctx.font = '100% ' + theme.typography.fontFamily;
            ctx.fillText(numberFormated, 0, 0);
            ctx.restore();
          }
        } else {
          if (bbox_coords && bbox_coords.length > 0) {
            const angle = Math.atan2(
              bbox_coords[0][1] - bbox_coords[1][1],
              bbox_coords[0][0] - bbox_coords[1][0],
            );
            const x1 = bbox_coords[0][0] * factor;
            const x2 = bbox_coords[2][0] * factor;
            const y1 = bbox_coords[0][1] * factor;
            const y2 = bbox_coords[2][1] * factor;
            // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
            const textWidth = ctx.measureText(labelNumber).width;
            let textHeight = ctx.measureText('m').width;
            if (angle > 0.1 && angle < 1) {
              textHeight = ctx.measureText('em').width;
            }

            if (angle < 1) {
              ctx.translate(x1, y1);
              ctx.rotate(angle);
            } else {
              ctx.translate(x1, y2);
            }

            const dx = x2 - x1;
            const dy = y2 - y1;
            const scaleY = dy / textHeight;
            const scale = -dx / textWidth;
            if (scaleY < 1) {
              if (scaleY < 0) {
                ctx.scale(scale, -scaleY);
              } else {
                ctx.scale(scale, 1);
              }
            } else {
              ctx.scale(scale, scaleY);
            }
            if (readingCanvas) {
              // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
              if (angle > 0.1 && angle < 1) {
                ctx.fillText(labelNumber, -textWidth, textHeight / 2);
              } else {
                ctx.fillText(labelNumber, -textWidth, textHeight);
              }
            }
          }
        }
      } else {
        if (label?.bbox_coords && bbox_coords.length > 0) {
          ctx.lineWidth = '3';
          ctx.strokeStyle = theme.palette.secondary.main;
          if (
            !readingCanvas &&
            bbox_coords &&
            bbox_coords.length > 0
          ) {
            ctx.beginPath();
            ctx.moveTo(
              bbox_coords[0][0] * factor,
              bbox_coords[0][1] * factor,
            );
            ctx.lineTo(
              bbox_coords[1][0] * factor,
              bbox_coords[1][1] * factor,
            );
            ctx.lineTo(
              bbox_coords[2][0] * factor,
              bbox_coords[2][1] * factor,
            );
            ctx.lineTo(
              bbox_coords[3][0] * factor,
              bbox_coords[3][1] * factor,
            );
            ctx.closePath();
            ctx.stroke();
          }
        }
        if (!showLabelText && readingCanvas) {
          const words = value.split(' ');
          let line = '';
          let y = 40;
          // !TODO: Change factor based on the canvas size
          let percentage = 100 * factor;
          ctx.font = `${percentage}%` + theme.typography.fontFamily;
          ctx.fillStyle = theme.palette.secondary.main;
          if (!disableText) {
            for (let word of words) {
              const proposedLine = `${line} ${word}`;
              if (ctx.measureText(proposedLine).width >= canvas.width - margin) {
                ctx.fillText(line, 20, y);
                line = word;
                y += 20;
              } else {
                line = proposedLine;
              }
            }
            ctx.fillText(
              line,
              (canvas.width - ctx.measureText(line).width) / 2,
              y + margin,
            );
          }
        } else if (!splitView) {
          const angle = Math.atan2(
            bbox_coords[0][1] - bbox_coords[1][1],
            bbox_coords[0][0] - bbox_coords[1][0],
          );
          const x1 = bbox_coords[0][0] * factor;
          const x2 = bbox_coords[2][0] * factor;
          const y1 = bbox_coords[0][1] * factor;
          const y2 = bbox_coords[2][1] * factor;
          // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
          const words = value.split(' ');
          let line = '';
          let y = 40;
          for (let word of words) {
            const proposedLine = `${line} ${word}`;
            if (ctx.measureText(proposedLine).width >= canvas.width - margin) {
              ctx.fillText(line, 20, y);
              line = word;
              y += 20;
            } else {
              line = proposedLine;
            }
          }
          
          const textWidth = ctx.measureText(line).width;
          let textHeight = ctx.measureText('m').width;
          if (angle > 0.1 && angle < 1) {
            textHeight = ctx.measureText('em').width;
          }
  
          if (angle < 1) {
            ctx.translate(x1, y1 - 20);
            ctx.rotate(angle);
          } else {
            ctx.translate(x1, y2 + 20);
          }
  
          const dx = x2 - x1;
          const dy = y2 - y1;
          const scaleY = dy / textHeight;
          const scale = -dx / textWidth;
          if (scaleY < 1) {
            if (scaleY < 0) {
              ctx.scale(scale, -scaleY);
            } else {
              ctx.scale(scale, 1);
            }
          } else {
            ctx.scale(scale, scaleY);
          }
          // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
          if (angle > 0.1 && angle < 1) {
            ctx.fillText(line, -textWidth, textHeight / 2);
          } else {
            ctx.fillText(line, -textWidth, textHeight);
          }
        }
      }
    } else if (!["results", "second_stage"].includes(stage)) {
        if (stage === reading_stage) {
          ctx.lineWidth = '3';
          ctx.strokeStyle = theme.palette.secondary.main;
          ctx.fillStyle = theme.palette.secondary.main;
          parsed_data.forEach(item => {
            const {bboxes, texts} = item;
            if (!readingCanvas) {
              ctx.beginPath();
              ctx.moveTo(
                bboxes[0][0] * factor,
                bboxes[0][1] * factor,
              );
              ctx.lineTo(
                bboxes[1][0] * factor,
                bboxes[1][1] * factor,
              );
              ctx.lineTo(
                bboxes[2][0] * factor,
                bboxes[2][1] * factor,
              );
              ctx.lineTo(
                bboxes[3][0] * factor,
                bboxes[3][1] * factor,
              );
              ctx.closePath();
              ctx.stroke();
            } else {
              ctx.font = '100% ' + theme.typography.fontFamily;
              if (bboxes[1][0] * factor > bboxes[0][0] * factor) {
                ctx.save();
                const angle = Math.atan2(
                  bboxes[1][1] * factor - bboxes[0][1] * factor,
                  bboxes[1][0] * factor - bboxes[0][0] * factor,
                );
                const x1 = bboxes[0][0] * factor;
                const x2 = bboxes[2][0] * factor;
                const y1 = bboxes[0][1] * factor;
                const y2 = bboxes[2][1] * factor;
                const distance = Math.sqrt(
                  Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2),
                );
                // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
                let measureString = 'm';
                let dividerFactor = 1;
                if (Math.abs(angle) > 0.04) {
                  measureString = 'em';
                  dividerFactor = 2;
                }
                const textWidth = ctx.measureText(texts).width;
                let textHeight = ctx.measureText(measureString).width;
                ctx.translate(x1, y1);
                ctx.rotate(angle);
                const dy = Math.abs(y2 - y1);
                const scaleY = dy / textHeight;
                let scale = Math.abs(x2 - x1) / textWidth;

                if (scaleY / scale > 0.5) {
                  ctx.scale(scale, scaleY);
                } else {
                  ctx.scale(scale, scale);
                }
                ctx.fillText(texts, 0, textHeight / dividerFactor);
                ctx.restore();
              } else {
                const angle = Math.atan2(
                  bboxes[0][1] - bboxes[1][1],
                  bboxes[0][0] - bboxes[1][0],
                );
                const x1 = bboxes[0][0] * factor;
                const x2 = bboxes[2][0] * factor;
                const y1 = bboxes[0][1] * factor;
                const y2 = bboxes[2][1] * factor;
                // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
                const textWidth = ctx.measureText(texts).width;
                let textHeight = ctx.measureText('m').width;
                if (angle > 0.1 && angle < 1) {
                  textHeight = ctx.measureText('em').width;
                }
  
                if (angle < 1) {
                  ctx.translate(x1, y1);
                  ctx.rotate(angle);
                } else {
                  ctx.translate(x1, y2);
                }
  
                const dx = x2 - x1;
                const dy = y2 - y1;
                const scaleY = dy / textHeight;
                const scale = -dx / textWidth;
                if (scaleY < 1) {
                  if (scaleY < 0) {
                    ctx.scale(scale, -scaleY);
                  } else {
                    ctx.scale(scale, 1);
                  }
                } else {
                  ctx.scale(scale, scaleY);
                }
                // !TODO: Fix hardcoded values. Maybe use a math function to calculate the text size
                if (angle > 0.1 && angle < 1) {
                  ctx.fillText(labelNumber, -textWidth, textHeight / 2);
                } else {
                  ctx.fillText(labelNumber, -textWidth, textHeight);
                }
              }
            }
          })
        }
    }
  });
};

export const formatCurrency = (
  value,
  currency_symbol,
  currency_thousands_sep,
  currency_decimal_sep,
  currency_decimals,
) => {
  if (value == null) {
    return '';
  }
  var integer = value.split('.')[0];
  var decimal = value.split('.')[1];
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, currency_thousands_sep);
  if (decimal) {
    decimal = decimal.substring(0, currency_decimals);
  }
  if (decimal.length < currency_decimals) {
    decimal += '0' * currency_decimals;
  }
  value = currency_symbol + integer;
  if (decimal) {
    value += currency_decimal_sep + decimal;
  }
  return value;
};
