import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { withTranslation } from 'react-i18next';

// Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// QA base url
const { REACT_APP_QA_BASE_URL } = process.env;

// Styled components
const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const drawerWidth = 265;

const styles = {
  itemButton: {
    minHeight: 48,
    px: 2.5,
  },
  itemIcon: {
    minWidth: 0,
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontWeigh: 400,
  },
  divider: {
    background: 'white',
    marginTop: 8,
    marginBottom: 8,
  },
};

class SideMenu extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      views: [t('hip_app.menu.dataset_annotation'), t('hip_app.menu.session_progress'), t('hip_app.menu.walls')],
      urls: ['/datasets', '/progress', '/walls'],
      icons: ['pencil', 'stats-chart-outline', 'scan-outline'],
    };
  }

  componentDidUpdate(prevProps) {
    const { t } = this.props;
    if (prevProps.t !== t) {
      this.setState({
        views: [t('hip_app.menu.dataset_annotation'), t('hip_app.menu.session_progress'), t('hip_app.menu.walls')],
      });
    }
  }

  render() {
    const { open, handleDrawer, DrawerHeader, isDarkTheme } = this.props;
    const { views } = this.state;
    const { t } = this.props;

    return (
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: isDarkTheme ? 'paper.main' : 'primary.main',
          },
        }}>
        {/* Menu close button */}
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {!open ? (
              <IconComponent iconName={'chevron-forward-outline'} className={'icon'} />
            ) : (
              <IconComponent iconName={'chevron-back-outline'} className={'icon'} />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider style={{ background: open ? 'white' : 'inherit' }} />

        {/* Views navigation */}
        <List>
          {/* Legacy views */}
          {views.map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => { window.location.href = `${REACT_APP_QA_BASE_URL}${this.state.urls[index]}` }}
                sx={[
                  styles.itemButton,
                  { justifyContent: open ? 'flex-end' : 'center' },
                ]}>
                <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
                  <IconComponent iconName={this.state.icons[index]} className={'icon'} />
                </ListItemIcon>
                <ListItemText
                  style={styles.text}
                  primary={text}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}

          {/* New views */}
          <ListItem key="Visualizer" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={() => { window.location.href = "/visualizer" }}
              sx={[
                styles.itemButton,
                { justifyContent: open ? 'flex-end' : 'center' },
              ]}>
              <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
                <IconComponent iconName={'pricetag-outline'} className={'icon'} />
              </ListItemIcon>
              <ListItemText
                style={styles.text}
                primary={t("hip_app.menu.visualizer")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

export default withTranslation()(SideMenu);
